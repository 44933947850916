import { TabsDispatcher } from './components/tabsDispatcher';
import SimpleBar from 'simplebar';

document.addEventListener('DOMContentLoaded', () => {




	var buttonPrice = document.querySelectorAll('.product_info__button');
	[].forEach.call(buttonPrice, function(button) {
		button.addEventListener('click', function(){

			if (button.classList.contains('button_main') && button.closest('.product_info')) {
				var title = button.closest('.product_info').querySelector('.product_info__title').innerHTML;
				var inputPrice = document.querySelector('.phone_price').querySelector('.price_type');

				inputPrice.setAttribute('value', title);
				console.log(inputPrice);
			}

			if (button.classList.contains('button_static') && button.closest('.product_info')) {
				var title = button.closest('.product_info').querySelector('.product_info__title').innerHTML;
				var inputPrice = document.querySelector('.feedback_price').querySelector('.price_type');
				
				inputPrice.setAttribute('value', title);
				console.log(inputPrice);

			}
		});
	});

	var buttonGet = document.querySelectorAll('.get_price__button');
	[].forEach.call(buttonGet, function(button) {
		button.addEventListener('click', function(){

			var buttonText = button.innerHTML;
			console.log(buttonText);

		});
	});


	






	var simpleBar = document.querySelectorAll('.simple_bar');
	[].forEach.call(simpleBar, function(bar) {
		new SimpleBar(bar);
	});

	
	var pagePay = document.querySelector('.page_play')
	pagePay.addEventListener('click', function() {
		document.body.classList.add('page-pay');
	});

	var pagePayBack = document.querySelector('.button_back')
	pagePayBack.addEventListener('click', function() {
		document.body.classList.remove('page-pay');
	});


	setTimeout(function () {
		document.body.classList.add('page-loaded');
	}, 750);

	var buttons = document.querySelectorAll('.button');
	[].forEach.call(buttons, function(button) {
		button.addEventListener('click', function() {
			button.classList.toggle('on-clicked');
		});
	});

	TabsDispatcher.init();


});

